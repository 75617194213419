.teacher-profile-and-speaking {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  .teacher-profile-bottom-wrap {
    // padding: 10px 25px;
    padding: 8px 5px;
    background: #0052cc12;
    color: #0052cc;
    border-radius: 7px;
    display: flex;
    gap: 7px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
.profile-view-inner-wrap {
  .profile-header-top {
    margin-bottom: 30px;
  }
  .profile-image-content-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
  }
}
//====================================
.blog-details-single-card-item {
  min-height: 350px;
  margin-bottom: 25px;
  box-shadow: 0px 0px 20px 0px #e0e5ea;
  border-radius: 8px;
  overflow: hidden;
  .blog-image {
    height: 200px;
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .blog-card-bottom-content {
    padding: 15px 24px;
    hr {
      margin: 10px 0;
    }
    .post-author-single-post-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .post-author-part-left {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }
}
