//====================================
.main-content {
  .admin-chat-box-full-area-wrap {
    height: calc(100vh - 240px);
    margin-bottom: 0;
    .card-body {
      padding: 0;
      .admin-chat-box-main-area-wrap {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .admin-chat-box-left-area {
          width: calc(100% - 75%);
          border: 1.5px solid rgba(235, 235, 235, 1);
          padding: 15px;
          .admin-chat-list-search {
            .admin-chat-list-search-box {
              border: 2px solid #ebebeb;
              height: 40px;
              position: relative;
              border-radius: 8px;
              display: flex;
              align-items: center;
              input[type="search"] {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                border: none;
                background: none;
                padding-left: 34px;
              }
              svg {
                position: absolute;
                left: 10px;
                width: 22px;
                height: 22px;
                path {
                  fill: #999;
                }
              }
            }
          }
          .admin-chat-list-view {
            max-height: 60vh;
            overflow-y: scroll;
            background: #ffff;
            &::-webkit-scrollbar {
              width: 4px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
            .admin-chat-list-single-persion {
              width: 100%;
              border: 1px solid #efefef;
              margin: 15px 0;
              padding: 12px 16px;
              border-radius: 8px;
              cursor: pointer;
              position: relative;
              .unread-massage-indicator {
                position: absolute;
                width: 10px;
                height: 10px;
                background: rgba(0, 82, 204, 1);
                right: 15px;
                top: 30%;
                border-radius: 50%;
              }
              &:hover {
                background: #0052cc;
                h4 {
                  color: #fff;
                }
                p {
                  color: #fff;
                }
              }
              h4 {
                margin-bottom: 6px;
                font-size: 14px;
              }
              p {
                margin-bottom: 0;
                font-size: 12px;
              }
            }
          }
        }
        .admin-chat-box-right-area {
          width: 75%;
          background: #ffffff;

          height: 100%;
          position: relative;
          .admin-chat-box-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #efefef;
            padding: 10px 14px;

            position: relative;
            z-index: 9;
            background: #fff;
            height: calc(100% - 90%);
            .chat-profile-info {
              h4 {
                margin-bottom: 5px;
                font-size: 16px;
              }
              p {
                margin-bottom: 0;
                font-size: 13px;
              }
            }
            .chat-dec-end-confirm-right button {
              width: 100px;
              height: 36px;
              padding: 0;
              border: 2px solid rgba(0, 82, 204, 1);
              background: none;
              border-radius: 25px;
              font-size: 24px;
              color: rgba(0, 82, 204, 1);
              font-weight: 700;
            }
          }
          .admin-chat-box-content-body {
            width: 100%;
            position: absolute;
            bottom: 80px;
            left: 0;
            padding: 20px 30px;
            // max-height: 350px;
            overflow-y: scroll;

            // width: 100%;
            // position: absolute;
            // bottom: 80px;
            // left: 0;
            // padding: 20px 30px;
            // /* height: 350px; */
            // overflow-y: scroll;
            &::-webkit-scrollbar {
              display: none;
            }
            *::-webkit-scrollbar {
              display: none;
            }

            @media (max-width: 1366px) {
              height: 350px;
            }
            .admin-chat-content-inner-wrap {
              // display: flex;
              // align-items: flex-end;
              height: 100%;
              padding-bottom: 00px;
              justify-content: space-between;
              position: relative;
              top: 48px;
              .admin-chat-content-left {
                width: 50%;
                // display: flex;
                // justify-content: flex-start;
                .client-chat-content-info-left {
                  margin-bottom: 15px;
                  .single-chat {
                    display: flex;

                    gap: 15px;
                    img {
                      width: 150px !important;
                    }
                    .chat-client-profile {
                      width: 40px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background: #0052cc;
                      border-radius: 50%;
                      h6 {
                        margin-bottom: 0;
                        color: #fff;
                      }
                    }
                    .chat-client-content {
                      p {
                        border: 1px solid #0052cc;
                        padding: 4px 10px;
                        border-radius: 6px;
                        color: #0052cc;
                        margin-bottom: 4px;
                      }
                      span {
                        font-size: 10px;
                      }
                    }
                    .chat-client-issue-token-button {
                      display: flex;
                      align-items: center;
                      button {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        border: none;
                        background: none;

                        span {
                          width: 4px;
                          height: 4px;
                          display: block;
                          background: #000;
                        }
                      }
                    }
                  }
                }
              }

              .client-chat-content-right {
                width: 50%;
                // display: flex;
                // justify-content: flex-end;
                margin-left: auto;

                .single-chat {
                  display: flex;
                  justify-content: flex-end;
                  > * {
                    display: flex !important;
                    justify-content: flex-end !important;
                    align-items: flex-start !important;
                  }
                  img {
                    width: 150px !important;
                  }
                  .chat-client-content {
                    margin: 10px 0;
                    text-align: right;
                    p {
                      background: #0052cc;
                      padding: 12px 20px;
                      margin-bottom: 0;
                      color: #fff;
                      border-radius: 5px;
                      display: inline-block;
                    }
                    span {
                      display: block;
                      font-size: 10px;
                    }
                  }
                }

                .client-chat-content-right-inner-box {
                  display: flex;
                  justify-content: flex-end;
                  .single-chat-content-box {
                    background: #0052cc;
                    padding: 12px 20px;
                    margin-bottom: 30px;
                    border-radius: 5px;
                    p {
                      margin-bottom: 0;
                      color: #fff;
                    }
                  }
                }
                * * {
                  &::-webkit-scrollbar {
                    width: 0px;
                  }
                }
              }
            }
          }
          .admin-chat-box-text-input-bottom {
            position: absolute;
            width: calc(100% - 36px);
            bottom: 14px;
            padding: 10px 15px;
            left: 0;
            display: flex;
            max-height: 70px;
            align-items: center;
            border: 1px solid #ced4da;
            margin: 0 18px;
            border-radius: 8px;
            background: #fff;
            .input-massage-type-answer {
              width: 90%;
              height: 36px;
              line-height: 42px;
              * {
                height: 100%;
                padding-left: 36px;
                border: none;
              }
            }

            .pre-define-ques-answer-left {
              position: absolute;
              z-index: 8;
              left: 16px;
              button {
                color: #fff;
                border: none;
                font-size: 20px;
                background: #929aa099;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                padding: 0;
                justify-content: center;
                border-radius: 50%;
              }
            }
            .chat-upload-file {
              position: absolute;
              z-index: 8;
              right: 40px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;

              label {
                margin-bottom: 0;
                cursor: pointer;
              }
              input {
                visibility: hidden;
                width: 40px;
              }
            }
            .chat-send-massage-button {
              position: absolute;
              right: 14px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              cursor: pointer;
              justify-content: center;
             
              svg {
                path {
                  fill: rgb(0, 82, 204);
                }
              }
            }
            .chat-image {
              width: 100%;
              height: auto;
              border-radius: 10px;
              position: absolute;
              bottom: 60px;
              background: #fff;
              padding: 10px;
              height: 70px;
              border: 1px solid #d9d9d960;
              left: 0;
              .chat-image-single-box {
                width: 50px;
                height: 50px;
                position: relative;
              }
              img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }
              i {
                position: absolute;
                top: -13%;
                right: -13%;
                font-size: 16px;
                color: red;
                cursor: pointer;
                border: 1px solid red;
                border-radius: 50%;
                background: #fff;
              }
              i.image-spin {
                color: black !important;
              }
            }
          }
        }
        .empty-image-empty-massage {
          width: 75%;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
}
.view-token-details-modal {
  p {
    margin-bottom: 0;
  }
  h6 {
    font-size: 20px;
  }
  button {
    border: 2px solid rgba(0, 82, 204, 1);
    display: block;
    padding: 8px 30px;
    width: 100%;
    margin-top: 30px;
    background: #fff;
    font-size: 16px;
    border-radius: 6px;
    color: #0052cc;
  }
}
