@import "../../assets/scss/theme.scss";

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }
.dashboard-main {
  .student-enrollment-chart {
    background: #fff;
    padding: 20px;
    box-shadow: 0px 10px 20px #12263f08;
    border-radius: 12px;
    height: 100%;
    .enrollmet-graph-controll-title-area {
      display: flex;
      align-items: flex-end;
      h1 {
        margin-bottom: 0;
        line-height: 1;
        font-size: 32px;
        font-weight: 700;
        @media (max-width: 767px) {
          font-size: 18px !important;
        }
      }
      .enrollment-balance-control-view {
        margin: 0 10px;
        .balance-up-count {
          color: #34c38f;
        }
        .balance-down-count {
          color: red;
        }
      }
    }
  }
  .new-student-profile-list-single-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      &:first-child {
        gap: 5px;
        span {
          position: relative;
          font-size: 14px;
          i {
            width: 100%;
            position: relative;
            top: 1px;
            font-size: 24px;
          }
        }
      }
      &:last-child {
        gap: 5px;
        span {
          position: relative;

          i {
            width: 100%;
            font-size: 24px;
          }
        }
      }
    }
    .profile-left-area > span:first-child {
      width: 24px;
    }
  }
  // ========================================================================
  .new-student-profile-list-single-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0px 10px 20px #12263f08;
    border-radius: 8px;
    border: 1px solid #e4e4e4;
  }
  .student-enrollment-chart > h2 {
    position: relative;
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 20px;
  }

  // .student-enrollment-chart h2:after {
  //   content: "";
  //   position: absolute;
  //   width: 6px;
  //   height: 100%;
  //   background: #0052cc;
  //   left: -20px;
  //   border-radius: 0px 5px 5px 0px;
  // }

  .profile-right-area > span {
    font-size: 14px;
    color: #0052cc;
  }
  .enrollment-stats-filtering-right {
    display: flex;
    width: 230px !important;
    justify-content: flex-end;
    gap: 15px;

    @media (max-width: 576px) {
      width: 120px !important;
    }
  }
  .student-enrollment-chart.populer-package {
    .apexcharts-legend > {
      * {
        width: 100%;
        justify-content: center;
        margin-top: 7px !important;
        span.apexcharts-legend-marker {
          width: 20px !important;
          height: 20px !important;
          border-radius: 5px !important;
        }
      }
    }
  }
  .new-student-profile-list-single-part > *:first-child span > span {
    display: block;
    color: #74788d;
    font-size: 12px;
    &:first-child {
      font-size: 16px;
      color: #000000;
    }
    &.new-student-profile-top-selling-package {
      display: flex;
      gap: 5px;
      i {
        width: unset;
        font-size: 12px;
        color: #f1b44c;
      }
    }
  }
  select.year-select-main-wrap {
    padding: 5px 16px;
    height: 38px;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    width: 110px;
  }
  .profile-left-area {
    span.top-selling-package-inner-image-wrap img {
      width: 80px;
      height: 50px;
      display: block;
      margin-right: 10px;
    }
  }
  .new-student-profile-list-single-part.top-selling-package {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .student-enrollment-header-inner-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    h2 {
      font-weight: 700;
      font-size: 20px !important;
      margin-bottom: 8px;
      @media (max-width: 767px) {
        font-size: 14px !important;
        // width: 150px;
      }
    }
    // > div {
    //   width: 120px;
    // }
    .year-select-main-wrap {
      &::placeholder {
        color: #000 !important;
      }
      > * {
        background-color: #fff !important;
        * {
          color: #000 !important;
        }
      }
    }
  }
  //=========================================
  .total-revenue-card-single-item {
    display: flex;
    align-items: center;
    gap: 15px;
    h6 {
      margin-bottom: 0;
      color: #0052cc;
    }
  }
  .student-per-package-single-card.card {
    background: #1b3f73;
    .count-with-package-header-left h3 {
      color: #fff;
    }
    .count-with-package-header-right i {
      color: #fff;
    }
  }
  .student-revenue-package-single-card.card {
    background: #0052cc;
    .count-with-package-header-left h3 {
      color: #fff;
    }
    .count-with-package-header-right i {
      color: #fff;
    }
  }
  .dashboard-student-count-with-package {
    .student-count-with-package-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .count-with-package-header-left h3 {
        @media (max-width: 576px) {
          font-size: 20px;
        }
        @media (max-width: 400px) {
          font-size: 16px;
        }
        font-size: 20px;
        font-weight: 600;
      }
      .count-with-package-header-right i {
        font-size: 26px;
        cursor: pointer;
      }
    }
    .dashboard-student-count-with-package-list {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      @media (min-width: 1100px) {
        gap: 12px;
        justify-content: center;
      }
      @media (max-width: 991px) {
        justify-content: space-between;
      }
      // justify-content: space-between;
      .single-exam-name {
        // width: 22%;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        width: 22%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 0px 10px 0px #1860ce40;
        padding: 10px 15px;
        border-radius: 6px;
        background: #fff;

        @media (max-width: 991px) {
          width: 22%;
        }
        @media (max-width: 767px) {
          width: 46%;
        }
        @media (max-width: 576px) {
          width: 46%;
        }
        @media (max-width: 359px) {
          width: 100%;
          text-align: center;
        }
        @media (min-width: 992px) {
          width: 46%;
        }
        @media (min-width: 1100px) {
          width: 30%;
        }
        @media (min-width: 1400px) {
          width: 22%;
        }
        p {
          font-size: 13px;
          color: #74788d;
        }
        h4 {
          font-size: 28px;
          font-weight: 800;
          color: #231f20;
          text-align: center;
        }
      }
    }
  }
  //================================
  .ielts-xam-avarage-score-area-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    @media only screen and (max-width: 1280px) {
      justify-content: center;
    }
    .single-ielts-xam {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 200px;
      border: 2px solid;
      padding: 14px;
      border-radius: 8px;
      @media (min-width: 1200px) {
        width: 220px;
      }
      @media (max-width: 767px) {
        width: 46%;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
      @media (min-width: 1200px) {
        width: 220px;
      }
      @media (min-width: 1400px) {
        width: 18%;
      }
      .single-ielts-xam-left-icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 26px !important;
          height: 26px !important;
        }
      }
      .single-ielts-xam-right-content {
        p {
          font-size: 16px;
          margin-bottom: 5px;
        }
        h4 {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 0px;
        }
      }
      &.overall-area {
        border-color: #ff8a8e !important;
        background: #fff8f8;
        .single-ielts-xam-left-icon {
          border-color: #ff8a8e !important;
          background: #fff;
        }
      }
      &.reading-area {
        border-color: #0e8ae3 !important;
        background: #f9fcff;
        .single-ielts-xam-left-icon {
          border-color: #0e8ae3 !important;
          background: #fff;
        }
      }
      &.speaking-area {
        border-color: #fdac22 !important;
        background: #fffcf8;
        .single-ielts-xam-left-icon {
          border-color: #fdac22 !important;
          background: #fff;
        }
      }
      &.listening-area {
        border-color: #04b200 !important;
        background: #f9fff9;
        .single-ielts-xam-left-icon {
          border-color: #04b200 !important;
          background: #fff;
        }
      }
      &.writing-area {
        border-color: #5d5cdd !important;
        background: #ffffff;
        .single-ielts-xam-left-icon {
          border-color: #5d5cdd !important;
          background: #fff;
        }
      }
    }
  }
  //================================================

  .student-enrollment-chart.populer-package {
    position: relative;
    .stats-area-title-graph {
      height: 15%;
      h2 {
        position: relative;
        font-size: 22px;
        font-weight: 700;
        @media (max-width: 767px) {
          font-size: 16px;
        }
        &::after {
          height: 100%;
        }
      }
    }
  }
  .new-student-list-items-wrap {
    height: 70%;
    .stats-chart-area-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .stats-single-bar {
        display: flex;
        gap: 15px;
        .stats-single-bar-left {
          width: 70px;
          @media (max-width: 767px) {
            margin-bottom: 10px;
          }
          h5 {
            font-size: 10px;
          }
          @media (max-width: 767px) {
            h5 {
              font-size: 13px;
            }
          }
        }
        .stats-single-bar-middle {
          height: 20px;
          background: #f0f0ff;
          width: 80%;
          border-radius: 8px;
          position: relative;
          // overflow: hidden;
          .stats-male {
            width: 0px;
            background: #c893fd;
            position: absolute;
            height: 100%;
            // z-index: 1;
            border-radius: 8px;
            transition: 0.5s;
            .state-male-hovar-tooltip {
              position: absolute;
              bottom: 5px;
              visibility: collapse;
            }
            &:hover {
              .state-male-hovar-tooltip {
                visibility: visible;
                z-index: 99;
                .card {
                  border: 1px solid #cacaca;
                  .card-header {
                    border-bottom: 1px solid #cacaca;
                  }
                }
              }
            }
          }
          .stats-female {
            width: 0;
            background: #2879fe;
            position: absolute;
            height: 100%;
            z-index: 2;
            border-radius: 8px;
            transition: 0.5s;
            .state-female-hovar-tooltip {
              position: absolute;
              bottom: 5px;
              visibility: collapse;
            }
            &:hover {
              .state-female-hovar-tooltip {
                visibility: visible;
                .card {
                  border: 1px solid #cacaca;
                  .card-header {
                    border-bottom: 1px solid #cacaca;
                  }
                }
              }
            }
          }
        }
        .stats-single-bar-right {
          width: 70px;
        }
      }
    }
  }
  .enrollment-stats-filtering-right.form-select-enrollment-area {
    width: 320px !important;
    @media (max-width: 767px) {
      width: 280px !important;
      margin-bottom: 10px;
    }
    .form-select-enrollment-single {
      width: 110px;

      select.year-select-main-wrap.form-select {
        width: 100%;
        @media (max-width: 767px) {
          font-size: 11px !important;
        }
      }
      &.select-package-options {
        font-size: 13px;
        width: 190px !important;
        @media (max-width: 767px) {
          width: 150px !important;
          font-size: 11px;
        }
      }
    }
  }
}
.page-content.dashboard-main {
  .container-fluid {
    @media (max-width: 576px) {
      .row > * {
        position: relative;
        padding: 0;
      }
    }
  }
  .student-enrollment-chart {
    .apexcharts-legend.position-bottom.apexcharts-align-center,
    .apexcharts-legend.position-top.apexcharts-align-center {
      justify-content: flex-start;
    }
    @media (max-width: 767px) {
      .apexcharts-legend.apexcharts-align-center.position-bottom {
        display: none !important;
      }
      // .apexcharts-legend-text {
      //   color: #74788d !important;
      //   font-family: var(--bs-font-sans-serif) !important;
      //   font-size: 11px !important;
      // }
    }
  }
}
