.account-pages.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  > div {
    width: 100%;
  }
}
.student-login-main-wrap {
  min-height: 86vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 20px 0; */
}
.student-login-main-wrap.signup-page {
  /* height: 95vh; */
}
.student-account-form-wrap {
  width: 550px;
  padding: 4%;

  background: #253642;
  box-shadow: 0px 10px 20px #12263f08;
  border-radius: 8px;
  min-height: 430px;
  @media (max-width: 767px) {
    width: 450px;
    min-height: 380px;
  }
  @media (max-width: 576px) {
    width: 350px;
    min-height: 380px;
  }
  @media (max-width: 420px) {
    width: 280px;
    min-height: 380px;
  }
}
.account-form-title {
  text-align: center;
}

.account-form-title h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 40px;
}
.account-btn-all-page.btn.btn-primary {
  display: block;
  width: 100%;
}
.account-with-social-media-link {
  text-align: center;
  padding-top: 40px;
}
.account-with-social-media-link h5 {
  margin-bottom: 20px;
}
.account-with-social-media-single-link {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 15px;
}
.account-with-social-media-single-link > a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #1877f2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
a.account-with-social-media-google-plus {
  background: #ea4434;
}
.account-with-social-media-single-link > * > img {
  width: 30px;
  height: 30px;
}

.forgot-password {
  margin-top: 15px;
  margin-bottom: 15px;
}

.forgot-password > a {
  color: #74788d;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.new-account > p {
  color: #495057;
}
.new-account > p > a {
  font-weight: 600;
  text-decoration: none;
  color: #556ee6;
}
.student-account-form-wrap.reset-password-student {
  min-height: 400px;
  margin-top: 70px;
}
.student-login-main-wrap.pass-reset {
  align-items: baseline;
}
.password-show-hide-wrap-class {
  position: relative;
}
.password-show-hide-wrap-class span {
  position: absolute;
  top: 40px;
  right: 15px;
  z-index: 9999;
}
.update-password-main-wrap {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  .update-password-inner-wrap {
    .card {
      margin-bottom: 0px;
      box-shadow: 0 0.75rem 1.5rem #12263f00 !important;
    }
    background: #fff;
    padding: 30px;
    border-radius: 15px;
  }
}
.update-password-left-image img {
  width: 400px;
}

//==============

.student-login-main-wrap-main-area-wrap {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 991px) {
    background-size: cover;
  }
  header.header-top-area-wrap {
    height: 8vh;
    display: flex;
    align-items: center;
    background: #fff;
  }
  .xam-pro-login-body-area-wrapper {
    height: 84vh;
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding-top: 90px;

    .form-group label {
      color: #fff;
    }
    .login-form-submit-button {
      background: #e8f0fe;
      color: #000;
      border: 2px solid #e8f0fe;
      font-weight: 600;
      font-size: 14px;
      margin-top: 10px;
    }
    .form-group {
      .form-control {
        background-clip: border-box;
      }
    }
    .account-form-title.teacher h3 {
      color: #fff;
      font-size: 26px;
    }
  }
  footer.footer-main-area-wrap-bottom {
    display: flex;
    justify-content: space-between;
    height: 8vh;
    background: #101010;
    align-items: center;
    .footer-copy-right-area-inner-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin-bottom: 0;
        color: #ffffff87;
      }
    }
  }
}
