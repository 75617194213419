.teacher-profile-and-speaking {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  .teacher-profile-bottom-wrap {
    // padding: 10px 25px;
    padding: 8px 5px;
    background: #0052cc12;
    color: #0052cc;
    border-radius: 7px;
    display: flex;
    gap: 7px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
.profile-view-inner-wrap {
  .profile-header-top {
    margin-bottom: 30px;
  }
  .profile-image-content-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
  }
}
