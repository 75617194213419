.banner-add-modal-button-top {
  display: flex;
  justify-content: flex-end;
  button {
    width: unset !important;

    border: none;
    color: #fff;

    display: flex;
    align-items: center;
    gap: 10px;
  }
}
///=============================
.banner-single-card-wrap {
  width: 100%;
  min-height: 250px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 0px #efefef;
  border-radius: 8px;
  padding: 24px;

  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &.hide {
    border: 4px solid #ddd;
    .banner-single-card-header-right button {
      color: #556ee6;
    }
  }
  &.show {
    border: 4px solid #02dc18;
    .banner-single-card-header-right button {
      color: #556ee6;
    }
  }
  .banner-single-card-header {
    display: flex;
    justify-content: space-between;
    .banner-single-card-header-left button {
      border: none;
      width: 40px;
      height: 40px;
      display: block;
      border-radius: 6px;
    }
    .banner-single-card-header-right button {
      border: none;
      display: flex;
      padding: 8px 20px;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 6px;
      i {
        font-size: 22px;
      }
    }
  }
}
