.student-courses-card img {
  width: 100%;
  height: 230px;
  background-color: #fff;
  border: none;
  outline: none;
}
.student-courses-review img {
  height: 18px;
  display: inline-block;
  width: 18px;
}

.student-courses-review {
  display: flex;
  margin-bottom: 15px;
}
p.student-courses-timeline.card-text img {
  width: 25px;
  margin-right: 10px;
  height: 25px;
}
.student-courses-review > span {
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -2px;
}
p.student-courses-timeline.card-text {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 14px;
  color: #74788d;
}
.student-courses-card-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.student-courses-card-button p {
  margin-bottom: 0;
}
.student-courses-card {
  margin-bottom: 25px;
}

.student-courses-card > .card {
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 12px 24px #12263f08;
  border: none;
  overflow: hidden;
}
.student-courses-card > .card .card-body {
  padding: 24px;
}
.bookmark-button-wrap img {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #fff;
  right: 15px;
  top: 15px;
  border: 1px solid #0052cc;
  border-radius: 3px;
  padding: 4px;
}
.package-area-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  gap: 50px;
}

.package-title-area-left-part h4 {
  margin-bottom: 0px;
}

.package-title-area-left-part p {
  margin-bottom: 0;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 7px;
  vertical-align: 0em;
  content: "\f107";
  border-top: 0em solid;
  border-right: 0em solid transparent;
  border-bottom: 0;
  border-left: 0em solid transparent;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.ieltspackage-main-wrap {
  padding: 200px 0;
  padding-bottom: 50px;
}

/* ================================= */
.gmat-package-main-wrap {
  padding-top: 250px;
  padding-bottom: 100px;
}
.course-single-part-right h3 {
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
  color: #1e266d;
}
.course-single-part-right ul {
  padding-left: 0;
}
.course-single-part-right ul li {
  list-style: none;
  display: flex;
  gap: 30px;
  margin-bottom: 25px;
  justify-content: flex-start;
  text-align: left;
}
.course-single-part-right p {
  color: #516986;
  font-size: 20px;
}
.course-single-part-right > ul > li > p {
  margin-bottom: 0;
}
.course-single-part-right > ul > li > p > span {
  font-weight: 600;
  display: inline-block;
  /* padding-left: 15px; */
  color: #0052cc;
}

/* .course-single-part-right ul li img {
  display: block;
  overflow: hidden;
  width: 34px;
} */

/* ================================  */
.gmat-package-main-wrap.gre {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0;
}

.gmat-package-main-wrap.ielts {
  padding: 70px 0;
}
.ieltspackage-main-wrap-all-package {
  padding: 70px 0;
  padding-bottom: 0;
}
.student-courses-card-button > * > a {
  color: #fff;
  text-decoration: none;
}
a.student-courses-review-package-details-btn {
  text-decoration: none;
  color: #253642;
}
a.student-courses-review-package-details-btn > .card-title.h5 {
  font-size: 18px;
  padding-left: 3px;
}
/* .gmat-content-right-wrap {
  min-height: 500px;
} */
.gmat-imageleft-wrap img {
  width: 100%;
  height: auto;
}
.gmat-package-main-wrap-entry {
  padding: 80px 0;
  min-height: 86vh;
}
button.notify-main-btn {
  background: #0052cc;
  border-color: #0052cc;
  display: flex;
  align-items: center;
  gap: 10px;
}
.card-img-top.gift-popup-package {
  min-height: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.package-details-content-title {
  margin-bottom: 16px;
}
.package-price-information {
  margin-bottom: 4px;
  font-size: 14px;
}
.package-price-information-top-wrap > h4 {
  font-size: 22px;
  font-weight: 600;
}
.card-img-top.package-image-preview {
  background-position: center;
  height: 230px;
  background-size: cover;
  background-repeat: no-repeat;
}
.card-img-top.package-card-image {
  min-height: 230px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.create_package {
  padding: 20px 0;
  .form-control {
    border: 1px solid #dfdfdf !important;
    background: transparent;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 14px;
    color: #727d88;
    font-weight: 300;
    margin-bottom: 0 !important;
    height: 40px;
    border-radius: 6px;
    &:hover,
    &:focus {
      border: 1px solid #dfdfdf !important;
    }
  }
  h4 {
    color: #495057;
    font-weight: 600;
  }
  &_title {
    margin-bottom: 20px;
  }
  .service-list {
    height: 100%;
    &.height {
      height: 380px;
    }
    .single-service {
      padding: 20px 15px;
      border: 1px solid #e1e1e180;
      // margin: 15px 0;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      &_details {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          width: 40px !important;
          height: 40px !important;
        }
        h5 {
          font-size: 16px;
        }
      }
      > * > h5 {
        font-size: 16px;
      }
    }
    .empty-list {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
    }
  }
  .height2 {
    height: 420px;
  }
  .padding_30 {
    padding: 20px;
    h5 {
      font-size: 16px;
    }
    .question_field {
      .mb-3 {
        margin-bottom: 0 !important;
      }
    }
  }
  .set_question {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    h5 {
      font-size: 16px;
    }
    .question_field {
      max-width: 100px;
      .form-control {
        border-radius: 5px !important;
        margin-bottom: 0 !important;
      }
    }
  }
  .buttons {
    display: flex;
    gap: 10px;
    .button {
      width: 50%;
      padding: 1rem;
    }
    .draft {
      background-color: #0052cc1a !important;
    }
    .createPackage {
      background-color: #0052cc !important;
      color: #ffffff;
    }
  }
}
.new-create-package-list select {
  height: 40px;
  font-size: 13px;
}
