.writing-ques-inner-content {
  width: 80%;
  margin: auto;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}
.writing-ques-img-upload-main-wrap {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px dashed #ced4da;
  border-radius: 0.25rem;
  input {
    display: none;
  }
}
.writing-ques-action-button-area {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  button {
    border: none;
    padding: 7px 20px;
    border-radius: 5px;
    background: none;
    &:first-child {
      border: 1px solid rgba(0, 82, 204, 1);
    }
    &:last-child {
      background: rgba(0, 82, 204, 1);
      color: #fff;
      border: 1px solid rgba(0, 82, 204, 1);
    }
  }
}
span.form-label {
  margin-bottom: 10px;
  display: block;
}
.position-absolute > {
  span.pill {
    color: #fff;
    // background: none !important;
    position: relative;
    // &::after {
    //   content: "";
    //   width: 10px;
    //   height: 10px;
    //   position: absolute;
    //   background: rgb(52, 195, 143);
    //   left: -7px;
    //   top: 20%;
    //   border-radius: 50%;
    // }
  }
  span.deactive {
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      background: red;
      left: -7px;
      top: 20%;
      border-radius: 50%;
    }
  }
  span.pending {
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      background: yellow;
      left: -7px;
      top: 20%;
      border-radius: 50%;
    }
  }
}
.writing-header-answer-top {
  display: flex;
  justify-content: space-between;
  h4 {
    margin-bottom: 20px;
  }
}
.preview-answer-container-main-wrap {
  width: 80% !important;
  margin: auto;
  .card-body {
    padding: 50px;
  }
}
.writing-academic-ques > h5 {
  color: rgba(0, 82, 204, 1);
  position: relative;
  margin-bottom: 25px;
  &::after {
    content: "";
    width: 4px;
    height: 100%;
    background: rgba(153, 194, 255, 1);
    position: absolute;
    left: -10px;
    top: 0;
  }
}
.writing-academic-ques {
  text-align: center;
  h4 {
    text-align: left;
  }
  h5 {
    text-align: left;
  }
  p {
    text-align: left;
    font-size: 16px;
  }
}
.writing-ques-delete-button-bootom {
  display: flex;
  justify-content: flex-end;
  button {
    border: none;
    background: rgba(0, 82, 204, 1);
    color: #fff;
    padding: 7px 20px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }
}
