.exam-data-review-single-data {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 0.75rem 1.5rem #12263f08;
  height: 100%;
  &.submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
