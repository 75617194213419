@import "./variables.scss";

.dashboard_card1 {
  background-color: $dashboard_card1;
}

.dashboard_card2 {
  background-color: $dashboard_card2;
}

.dashboard_card3 {
  background-color: $dashboard_card3;
}
.dashboard_card4 {
  background-color: $dashboard_card4;
}
.dashboard_card5 {
  background-color: $dashboard_card5;
}

.dashboard_card6 {
  background-color: $dashboard_card6;
}

.pill {
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  padding: 2px 7px;
}

.removeShadow {
  &:focus {
    box-shadow: none;
  }
}

.drTextareaDiv {
  & > div {
    height: 100%;
  }
}

.custom-dot {
  background-color: #f4f9fd;
  padding: 10px;
  font-size: 18px;
  border-radius: 8px;
}
.spanColor {
  color: #91929e;
}

table {
  vertical-align: middle !important;
}

.dropdown-toggle::after {
  display: none !important;
}
