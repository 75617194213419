.empty-time-slot-main-wrap {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    display: inline-block;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    color: #9fa3a6;
    margin-bottom: 25px;
  }
}
.d-flex.speaking-time-slot-all-data-select {
  gap: 10px;
  .speaking-time-slot-single-data-select {
    width: 120px;
  }
}
.single-exam-schedule {
  background: #fff;
  display: flex;
  padding: 25px;
  border-radius: 10px;
  gap: 15px;
  position: relative;
  align-items: center;
  .single-exam-schedule-left-icon {
    width: 50px;
    height: 50px;
    border: 2px solid #0052cc;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    img {
      width: 25px;
    }
  }
  .single-exam-schedule-left-content {
    h4 {
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 0px;
    }
  }
  .single-exam-schedule-left-edit-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    img {
      width: 20px;
    }
  }
}
.single-student-time-main-wrap {
  background: #fff;
  padding: 25px;
  border-radius: 4px;
  .single-exam-time-schedule {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    &:first-child {
      border-bottom: 1px solid #eee;
    }
    .single-exam-time-left-part {
      display: flex;
      gap: 15px;
      h4 {
        margin-bottom: 4px;
      }
      p {
        margin-bottom: 0px;
      }
    }
    .single-exam-time-right-part button {
      display: inline-block;
      padding: 8px 25px;
      background: #0052cc;
      color: #fff;
      border-radius: 10px;
    }
    .single-exam-time-right-part-student a i {
      font-size: 30px;
    }
  }
}
