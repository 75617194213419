.speaking-main-container-wrap {
  padding: 10px 0;
  min-height: 86vh;
  .header-top-button-title {
    h4 {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 25px;
      span {
        display: block;
      }
    }
  }
  .speaking-exam-date-bottom-wrap {
    background: #fff;
    padding: 50px 50px;
    border-radius: 10px;
    .speaking-exam-date-content-wrap > * {
      width: 100%;
    }
    .react-calendar {
      border: none;
      margin: auto;
      background: transparent;
    }
  }
}

.speaking-exam-selected-time {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  .speaking-selected-time-button {
    cursor: pointer;
    border: 1px solid #0052cc;
    color: #0052cc;
    padding: 10px 25px;
    border-radius: 7px;
    background: #f8f8fb;
    width: 30%;
    text-align: center;
  }
  .selected {
    background: #5e6b80;
    color: #fff;
  }
  .added {
    background: #42536c;
    color: #fff;
  }
}
.form-submit-main-time-wrap {
  .time-selected-submit-btn {
    display: flex;
    justify-content: flex-end;
    button {
      border: none;
      color: #fff;
      padding: 12px 25px;
      border-radius: 7px;
      background: #0052cc;
    }
  }
}
.speaking-package-listed-confirmation {
  display: flex;
  justify-content: center;
  text-align: center;
  img {
    height: 100px;
    margin-bottom: 25px;
  }
  h3 {
    margin: 15px 0;
    margin-top: 0;
  }
  p {
    margin-bottom: 25px;
  }
  & > * > a {
    border: none;
    color: #fff;
    padding: 12px 25px;
    border-radius: 7px;
    background: #0052cc;
    text-decoration: none;
    display: inline-block;
    margin-top: 15px;
  }
}
.speaking-package-listed-view-wrap {
  text-align: center;
  h3 {
    margin-bottom: 15px;
  }
  h4 {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    gap: 14px;
    align-items: center;
    color: #0052cc;
    font-weight: 700;
    span {
      display: block;
    }
  }
  p {
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 15px;
  }
  a {
    border: none;
    color: #fff;
    padding: 12px 25px;
    border-radius: 7px;
    background: #0052cc;
    text-decoration: none;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
    &:hover {
      color: #fff;
    }
    img {
      margin-bottom: 0;
    }
    span:last-child {
      display: inline-block;
      padding-left: 6px;
    }
  }
}

.empty-time-slot-main-wrap.create-time-slot-wrap {
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
  }
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
  }
  button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    display: none;
  }
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    display: none;
  }
  .react-calendar__tile--active {
    background: none;
    font-weight: 700;
    color: #0052cc;
  }
  .react-calendar__month-view__days > * {
    margin-bottom: 20px !important;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.95em;
    margin-bottom: 20px;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #253642;
  }
  .react-calendar__tile--now {
    background: none;
    font-weight: 700;
    color: #0052cc;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: none;
  }
  button.react-calendar__navigation__label {
    text-align: left;
    padding-left: 22px;
    span {
      position: relative;
      font-weight: 400;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      line-height: 1;
      text-rendering: auto;
      display: inline-block;
      text-transform: none;
      padding-left: 23px;
      font-size: 16px;
      &:before {
        content: "\f017";
        font-family: "Font Awesome 5 Free";
        position: absolute;
        left: 0;
        top: 0px;
      }
    }
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: transparent;
  }
}
