.speaking-exam-date-bottom-wrap {
  display: flex;
  justify-content: center;
  .speaking-package-listed-view-wrap {
    text-align: center;
    width: 740px;
    background: #fff;
    padding: 50px;
    border-radius: 10px;
    h4 > {
      button {
        background: #0052cc33;
        padding: 7px 25px;
        margin-top: 10px;
        color: #0052cc70;
        font-weight: 600;
        border-radius: 5px;
        &:disabled {
          cursor: not-allowed;
        }
      }
      a {
        background: #0052cc33;
        padding: 8px 25px;
        margin-top: 10px;
        color: #0052cc70;
        font-weight: 600;
        border-radius: 5px;
      }
    }
  }
  .speaking-package-listed-view-wrap.enable-button h4 > * {
    color: #0052cc;
    &:hover {
      background: #0052cc;
      color: #fff;
    }
  }
}

.meet-button {
  background-color: #0052cc;
  padding: 8px 25px;
  margin-top: 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  border: none;
}

.teacher-speaking-exam-review-wrap {
  .teacher-speaking-exam-review-wrap-single-profile {
    width: 740px;
    margin: auto;
    .card-header {
      padding: 0;
      background: #f0f6ff;

      .exam-review-profile-image {
        display: flex;
        justify-content: center;
        img {
          width: 80px;
          height: 80px;
          display: block;
          background: #ddd;
          border-radius: 50%;
          position: relative;
          bottom: -40px;
        }
      }
    }
    .card-body {
      .exam-review-profile-content-wrap {
        padding-top: 40px;
        .profile-name {
          text-align: center;
          h4 {
            color: #253642;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
          }
        }
        .student-result-input-field-title {
          h5 {
            margin-bottom: 30px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            color: #253642;
            font-size: 20px;
          }
          .result-data-field-wrap {
            .single-result-data-wrap {
              display: flex;
              //   align-items: center;
              gap: 10px;
              width: 100%;
              .result-title {
                label {
                  margin-bottom: 0;
                  font-size: 14px;
                  width: 150px;
                  font-weight: 500;
                }
                p {
                  margin-bottom: 0;
                  font-size: 16px;
                  width: 150px;
                }
              }
              .result-field {
                width: 100%;
                input {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
