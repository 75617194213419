.back-arrow-icon-content-wrap h4 {
  display: flex;
  align-items: center;
  gap: 10px;
  * {
    display: block;
  }
}
.single-result-item-wrap {
  background: #fff;
  display: flex;
  gap: 15px;
  padding: 20px;
  border-radius: 6px;
  width: 100%;
  > span {
    &:first-child {
      width: 50px;
    }
    &:last-child {
      width: calc(100% - 60px);
    }
  }
  span {
    h5 {
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: 500;
      color: #343a40;
    }
    span {
      margin-bottom: 5px;
      display: block;
      color: #74788d;
    }
    p {
      margin-bottom: 5px;
      color: #eb5757;
      &.reviewed-answer {
        color: #27ae60;
      }
    }
  }
}

// =====================================
.ques-answer-list-review-main-wrap {
  width: 80%;
  background: #fff;
  margin: auto;
  padding: 30px;
  border-radius: 6px;
  .ques-answer-list-review-inner-wrap {
    .single-ques-answer-list {
      .accordion {
        .accordion-item {
          border: none;
          background: #fafafa;
          margin-bottom: 15px;
          padding: 15px 25px;
          .accordion-header {
            h5 {
              font-size: 20px;
              font-weight: 500;
            }
            button.accordion-button {
              background: #fafafa !important;
              //   box-shadow: inset 0 -1px 0 #c4c4c4;
              &:not(.collapsed) {
                color: #4d63cf;
                background-color: #eef1fd;
                box-shadow: inset 0 -1px 0 #c4c4c4;
              }
            }
          }
          //   &:nth-child(odd) {
          //     p {
          //       font-size: 18px !important;
          //     }
          //   }
          &:nth-child(even) {
            background: #fff;
            button.accordion-button {
              background: #fff !important;
            }
          }
          .ques-review-details {
            p {
              font-size: 20px;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}
.ques-review-answer-single-result {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  align-items: center;
  .result-answer-title {
    width: 150px;
    font-size: 16px;
    font-weight: 500;
  }
  .result-answer-dec {
    input {
      min-height: 40px;
      padding: 10px;
      border: 1px solid #aaaaaa;
      border-radius: 5px;
    }
  }
  button.teacher-mark-review-and-submit {
    background: #0052cc;
    color: #fff;
    padding: 8px 25px;
    border-radius: 5px;
    border: none;
  }
}
