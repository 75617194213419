.result-inner-area {
  padding: 50px 0;
}

.result-inner-area > h3 {
  margin-bottom: 30px;
}
.course-review-area-exam-page > span {
  color: #ffc150;
}
span.count-review-exam-page {
  color: #000;
  padding-left: 10px;
}
.course-review-area-exam-course-title {
  margin: 5px 0;
}
.course-result--profile-box.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}
.exam-result-profile---content {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  margin: 0;
}
.exam-result-feedback---content {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
  padding: 30px;
  gap: 20%;
}
.course-result--profile-box > img {
  height: 160px;
}
.result-main-area-inner-box {
  background: #fff;
  padding: 40px 0;
  border-radius: 10px;
}
.result-main-area-inner-box h4 {
  position: relative;
  padding-left: 20px;
}
.course-result--profile-box.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0 #dddddd5e;
}
.result-main-area-inner-box h4:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background: #fddd;
  left: -10 !important;
  top: 0;
  background: #ddd;
}
.exam-ques-your-position > ul {
  display: flex;
  padding-left: 0;
  gap: 20px;
}
.exam-ques-your-position > ul > li {
  list-style: none;
  font-weight: 600;
}
.exam-ques-your-position > ul > li {
  list-style: none;
}

.exam-ques-your-position > ul {
  display: flex;
  padding-left: 0;
  gap: 30px;
}

li.your-exam-answer {
  border-bottom: 2px solid #2068d2;
  color: #2068d2;
}
.course-result--profile-area {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
  margin: 24px 40px;
}
.course-result--profile-area.first-result-box {
  margin-bottom: 50px;
}
.course-result--profile-area > table {
  text-align: center;
}

.course-result--profile-area > table > thead {
  background: #f3f3f3;
}
span.ques-id-serial {
  width: 30px;
  height: 30px;
  background: #bcd2e7;
  display: inline-block;
  line-height: 30px;
  border-radius: 50%;
}
.course-result--profile-area > table > tbody > tr {
  border-bottom: 1px solid #f3f3f3;
}
.course-result--profile-area > table > tbody > tr:last-child {
  border-bottom: none;
}
span.correct-ans {
  color: #16eb52 !important;
  font-size: 22px !important;
}
span.false-ans {
  color: #ff1313 !important;
  font-size: 22px !important;
}
.course-result--profile-area > table > thead > tr > th {
  color: #253642 !important;
  font-size: 18px;
  font-weight: 400;
}
.course-result--profile-area > table > tbody > tr > td {
  font-weight: 400;
  font-size: 16px;
  color: #253642;
}
.course-result--profile-area > table > tbody > tr > td > span {
  font-weight: 400;
  font-size: 16px;
  color: #253642;
}

.result-data-field-wrap {
  .single-result-data-wrap {
    display: flex;
    //   align-items: center;
    gap: 10px;
    width: 100%;
    .result-title {
      label {
        margin-bottom: 0;
        font-size: 14px;
        width: 150px;
        font-weight: 500;
      }
      p {
        margin-bottom: 0;
        font-size: 16px;
        width: 150px;
      }
    }
    .result-field {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}

// ================
.exam-result-profile---content > span {
  font-weight: 600;
  font-size: 20px;
}

.exam-result-feedback---item {
  h6 {
    font-weight: 600;
    font-size: 22px;
  }
  p {
    color: #74788d;
    margin-bottom: 10px;
  }
}
.result-main-area-inner-box > h4 {
  margin-left: 40px;
}

.result-inner-area {
  .result-main-area-inner-box {
    > div > h4 {
      margin-left: 40px;
      margin-bottom: 30px;
    }
    .question-right-area-hidden-show-class {
      border: 1px solid #c4c4c4;
      padding: 0 0px;
      .exam-body-main {
        margin-top: 0;

        .passage-left-area-main-wrap {
          right: 0px;
        }
      }
    }
  }
}
.exam-name-ques-part-name-package-name {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  span {
    color: #74788d;
  }
}
.result-page-heder-page-title-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  i {
    font-size: 24px;
  }
}
.listening-exam-inner-wrap {
  border: 1px solid #ddd;
}

.result-main-area {
  .result-inner-area {
    .result-main-area-inner-box {
      .question-right-area-hidden-show-class {
        .exam-body-main {
          .answer-checkbox-mainwrap {
            .d-flex.align-items-center.py-2 {
              button.gapsInput {
                bottom: 0px;
              }
            }
          }
          .ques-serial {
            .d-flex.align-items-center.py-2 {
              > * {
                button.gapsInput {
                  bottom: 0px;
                  width: 140px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.result-data-field-wrap.mt-2.speaking-result-page {
  margin: 40px;
  background: #fafafa;
  padding: 40px;
  border-radius: 10px;
  .result-field {
    .form-control:disabled,
    .form-control[readonly] {
      background-color: #fff;
      opacity: 1;
      border: 1px solid #aaaaaa !important;
      color: #253642 !important;
    }
  }
}
// Global css from student panel

.package-bottom-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.package-bottom-btn p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.package-list-page-titlebar {
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 40px;
}

.package-list-page-titlebar > .row .row {
  align-items: center;
  justify-content: space-between;
}

.package-list-page-titlebar p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.position-relative button {
  width: 100%;
}

.card-packge-single-item > .card {
  border-radius: 5px;
  overflow: hidden;
}

.package-details-page {
  background: #fff;
  width: 70%;
  margin: auto;
  padding: 48px 10%;
  border-radius: 5px;
}

.package-details-page > .card {
  box-shadow: none;
}

.package-details-page img {
  border-radius: 5px;
}

.package-details-page td button {
  border: 1px solid #4f4f4f;
  background: none;
  padding: 5px 15px;
  border-radius: 5px;
}

.package-details-page .mt-0.card-title {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}

.package-details-page .mt-0.card-title p {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
}

textarea {
  position: relative;
  height: 120px;
}

textarea.form-control::placeholder {
  /* position: absolute; */
  width: 98%;
  top: 30px;
}

.create-question-set-link-box {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin: 50px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.reading-main-page {
  width: 90%;
  margin: auto;
  background: #fff;
  height: 100%;
  padding: 40px 7%;
  border-radius: 5px;
}

.mini-stats-wid.card {
  border: none;
  box-shadow: none;
}

.reading-main-page .card-body {
  padding: 0;
}

.multiple-question-set-wrap.true-false-page {
  border-bottom: 0;
}

.teacher-single-item {
  background: #fff;
  padding: 15px 0;
}

.teacher-single-item {
  background: #fff;
  padding: 15px 0;
  margin-bottom: 25px;
  border-radius: 5px;
}

.teacher-profile-data h4 {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.teacher-single-item button {
  border: none;
  position: absolute;
  right: 0;
  background: blue;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
}

.teacher-register-page {
  width: 50%;
  margin: auto;
}

.teacher-register-page input {
  width: 100%;
}

.mb-3.profile-image input {
  width: unset;
}

.mb-3.profile-image img {
  display: inline-block;
  margin-right: 15px;
}

.teacher-profile-data.card-body p {
  font-size: 12px !important;
}

.faq-content-inner-wrap {
  width: 60%;
  margin: 50px auto;
  background: #fff;
  padding: 50px 5%;
  border-radius: 5px;
}

.contect-email-btn-area {
  text-align: center;
  width: 300px;
  margin: auto;
}

.contect-email-btn-area {
  text-align: center;
  width: 300px;
  margin: 40px auto;
}

.input-email-form {
  border: 1px solid #0052cc;
  padding: 10px 30px;
  margin-top: 15px;
  border-radius: 4px;
}

.faq-single-item h5 {
  font-weight: 700;
}

.faq-single-item p {
  font-size: 13px;
}

.input-email-form a {
  font-family: "sora";
  color: #0052cc;
  /* font-weight: 500; */
  font-size: 15px;
}

.input-email-form a {
  font-family: "sora";
  color: #0052cc;
  /* font-weight: 500; */
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-email-form a img {
  display: block;
  margin-right: 5px;
}

.profile-image-area {
  display: flex;
  align-items: center;
}

.input-email-form a img {
  display: block;
  margin-right: 5px;
}

.profile-image-area {
  display: flex;
  align-items: center;
}

.profile-image-area img {
  width: 70px;
  height: 70px;
  display: block;
  background: #ddd;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
}

.student-profile-main-wrap {
  width: 50%;
  margin: 50px auto;
}

.student-profile-main-wrap > .card {
  padding: 40px 6%;
}

.student-profile-main-wrap > .card > h4 {
  margin-bottom: 30px;
}

.confirm-pass-main-area.card {
  padding: 40px 40px;
}

.confirm-pass-main-area.card > .card-body {
  padding: 0;
}

.card-bottom-area-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-bottom-area-wrap p {
  margin-bottom: 0;
  font-weight: 600;
}

.students-package-main-wrap h3,
h4 {
  margin-bottom: 20px;
}

.react-responsive-modal-modal {
  border-radius: 5px;
}

.student-exam-confirm-btn {
  padding: 25px 10px;
}

.student-exam-confirm-btn a {
  color: #fff;
}

.exam-ques-header-main-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exam-ques-header-main-wrap > div {
  display: flex;
  align-items: center;
}

.exam-ques-header-main-wrap > div > h4 {
  margin-bottom: 0;
}

.exam-ques-header-main-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #fff;
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  box-shadow: 0px 10px 20px #12263f08;
}

.exam-ques-footer-main-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.ques-footer-bottom {
  background: #0a244b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  color: #fff;
  height: 55px;
}

.exam-ques-footer-main-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

.ques-footer-bottom h4 {
  color: #fff;
  margin-bottom: 0;
}

.ques-footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #cfe2ff;
  height: 40px;
}

.ques-footer-top > * > * {
  margin-bottom: 0;
}

.exam-f-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 
.exam-f-left select.form-select {
  background-color: transparent;
  border: 1px solid transparent;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
} */
.exam-f-left select.form-select {
  border: 1px solid transparent;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  background-repeat: no-repeat !important;
  background-position: right -6px center !important;
  background-size: 26px 26px !important;
}

.exam-f-left select.form-select option {
  background-color: #0a244b;
}

.exam-h-right-button button {
  margin-left: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #fff;
}

.exam-h-right-button button:last-child {
  background: none;
  color: #fff;
}

.exam-f-right img {
  height: 20px;
  margin-right: 6px;
}

.exam-h-marks.exam-h-t-right-button button {
  padding: 4px;
  margin-right: 0;
  padding-left: 15px;
  font-size: 14px;
}

.exam-f-left img {
  height: 25px;
}

.exam-body-main {
  margin-top: 56px;
  background: #ffffff;
  height: 85vh;
  overflow-x: hidden;
  /* z-index: -1; */
  position: relative;
}

.reading-passage-wrap.right > img {
  position: absolute;
  left: 0px;
  top: 4px;
  background-color: #bad6ff;
  padding: 6px;
}

.passage-left-area-main-wrap {
  background: #e5f1fb;
  width: 100%;
  height: 80vh;
}

.reading-passage-wrap.left > img {
  position: absolute;
  right: 0px;
  top: 4px;
  background-color: #bad6ff;
  padding: 6px;
}

.passage-right-area-main-wrap {
  width: 100%;
  min-height: 80vh;
  background: #fff;
}

.q-p-single-part {
  display: flex;
  justify-content: space-between;
}

.passage-single-left-part {
  margin-right: 20px;
}

.student-exam-ques-left-content {
  padding: 50px;
}

.passage-left-area-main-wrap {
  background: #e5f1fb;
  width: 100%;
  min-height: 85vh;
  background-attachment: fixed;
  overflow-y: scroll;
  position: relative;
  right: -5px;
}

.passage-left-area-main-wrap::-webkit-scrollbar {
  width: 5px;
}

.passage-left-area-main-wrap::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.582);
  border-radius: 5px;
}

.ques-passage-container h4 {
  font-size: 24px;
  font-weight: 600;
}

.ques-passage-container p {
  font-size: 16px;
  line-height: 30px;
}

.ques-right-part-container {
  padding: 50px;
}

.student-note-btn p {
  background: #e6eafd;
  padding: 7px 12px;
  font-size: 14px;
  border-radius: 4px;
  color: #0052cc;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
}

.student-note-btn p > img {
  height: 20px;
  margin-right: 4px;
}

.ques-serial {
  padding: 10px 0;
}

.ques-serial h4 {
  font-weight: 600;
  font-size: 24px;
}

.student-ques-answer-part {
  padding: 30px;
  background: #f1f4fe;
  border: 1px solid #b3c3f8;
  border-radius: 3px;
}

.student-ques-answer-part ul {
  padding-left: 0;
  margin-bottom: 0;
}

.student-ques-answer-part ul li {
  display: inline-block;
  padding-right: 55px;
  padding-bottom: 15px;
}

.passage-right-area-main-wrap {
  border-left: 15px solid #dddd;
  width: 100%;
  height: 85vh;
  background: #fff;
  overflow-y: scroll;
  position: relative;
}

.passage-right-area-main-wrap::-webkit-scrollbar {
  width: 5px;
}

.passage-right-area-main-wrap::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.582);
  border-radius: 5px;
}

form.form-question-note textarea {
  background: #e5ebed;
  margin-bottom: 15px;
}

form.navbar_mobile_menu.hide {
  width: 0;
  height: 0;
  opacity: 0;
  /* transition: 0.5s;
  transform: scale(0);
  transform-origin: bottom; */
}

.exam-ques-header-main-wrap > div > h4 {
  margin-bottom: 0;
  margin-left: 10px;
}

.exam-f-left > button {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.ques-answer-modal-container {
  text-align: center;
  padding: 15px;
}

.answer-ques-list-color {
  display: flex;
  justify-content: center;
  gap: 2vw;
  align-items: center;
  margin-bottom: 30px;
}

.answered-ques span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #e68955;
  margin-right: 8px;
  border-radius: 50%;
  position: relative;
  top: 6px;
}

.unanswered-ques span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #bb551b;
  margin-right: 8px;
  border-radius: 50%;
  position: relative;
  top: 6px;
}

.ques-list-modal ul {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 15px;
  align-items: center;
  padding-left: 0;
  justify-content: center;
}

.ques-list-modal ul li {
  width: 30px;
  height: 30px;
  /* background: #e68955; */
  list-style: none;
  color: #fff;
  /* line-height: 30px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* .react-responsive-modal-modal {
  max-width: 420px;
} */

.question-right-area-hidden-class {
  width: 0;
  /* display: none; */
  transition: 0.5s;
}

.exam-body-main > * > .row {
  justify-content: center;
  transition: 0.5s;
}
.input-answer {
  display: inline-block;
}

.input-answer input {
  border: none;
  border-bottom: 1px dashed;
  width: 50px;
  margin: 0px 3px;
}
.checkbox-answer-single-item h5 .form-check.form-check-inline {
  display: flex;
  align-items: center;
}

.checkbox-answer-single-item h5 {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.checkbox-answer-single-item h5 span {
  margin-right: 10px;
}

.checkbox-answer-single-item h5 > div > label {
  margin-left: 10px;
}
.checkbox-answer-single-item h5 span {
  width: 25px;
  background: #ddd;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
}

.student-note-btn {
  display: flex;
  align-items: center;
}

.checkbox-ques-serial-no {
  display: flex;
  /* align-items: center; */
  margin-bottom: 15px;
  gap: 10px;
  /* align-items: center; */
}
.listening-ques-main-area-left-part .checkbox-ques-serial-no {
  align-items: center;
}

.checkbox-ques-serial-no > form {
  width: 120px;
}
.checkbox-ques-serial-no > h5 {
  width: 30px;
  position: relative;
  top: -10px;
}
.checkbox-ques-serial-no > p {
  width: 88%;
}
.checkbox-ques-serial-no h5 {
  width: 30px;
  height: 30px;
  background: #e6eafd;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  /* margin-right: 10px; */
  margin-bottom: 0px;
  font-size: 14px;
}

.checkbox-ques-serial-no p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  top: 0px;
}

.checkbox-ques-serial-no.option-select-ans select {
  width: 120px;
  margin-right: 10px;
}
table.table.table-borderless.table-striped.table-hover {
  border: 1px solid #ddd;
}

.cursor_pointers {
  cursor: pointer;
}
