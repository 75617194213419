.result-inner-area {
  padding: 50px 0;
}

.result-inner-area > h3 {
  margin-bottom: 30px;
}
.course-review-area-exam-page > span {
  color: #ffc150;
}
span.count-review-exam-page {
  color: #000;
  padding-left: 10px;
}
.course-review-area-exam-course-title {
  margin: 5px 0;
}
.course-result--profile-box.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}
.exam-result-profile---content {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  margin: 0;
}
.exam-result-feedback---content {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
  padding: 30px;
}
.course-result--profile-box > img {
  height: 160px;
}
.result-main-area-inner-box {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
}
.result-main-area-inner-box h4 {
  position: relative;
  padding-left: 10px;
}
.course-result--profile-box.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0 #dddddd5e;
}
.result-main-area-inner-box h4:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background: #fddd;
  left: 0;
  top: 0;
  background: #ddd;
}
.exam-ques-your-position > ul {
  display: flex;
  padding-left: 0;
  gap: 20px;
}
.exam-ques-your-position > ul > li {
  list-style: none;
  font-weight: 600;
}
.exam-ques-your-position > ul > li {
  list-style: none;
}

.exam-ques-your-position > ul {
  display: flex;
  padding-left: 0;
  gap: 30px;
}

li.your-exam-answer {
  border-bottom: 2px solid #2068d2;
  color: #2068d2;
}
.course-result--profile-area {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
}
.course-result--profile-area.first-result-box {
  margin-bottom: 50px;
}
.course-result--profile-area > table {
  text-align: center;
}

.course-result--profile-area > table > thead {
  background: #f3f3f3;
}
span.ques-id-serial {
  width: 30px;
  height: 30px;
  background: #bcd2e7;
  display: inline-block;
  line-height: 30px;
  border-radius: 50%;
}
.course-result--profile-area > table > tbody > tr {
  border-bottom: 1px solid #f3f3f3;
}
.course-result--profile-area > table > tbody > tr:last-child {
  border-bottom: none;
}
span.correct-ans {
  color: #16eb52 !important;
  font-size: 22px !important;
}
span.false-ans {
  color: #ff1313 !important;
  font-size: 22px !important;
}
.course-result--profile-area > table > thead > tr > th {
  color: #253642 !important;
  font-size: 18px;
  font-weight: 400;
}
.course-result--profile-area > table > tbody > tr > td {
  font-weight: 400;
  font-size: 16px;
  color: #253642;
}
.course-result--profile-area > table > tbody > tr > td > span {
  font-weight: 400;
  font-size: 16px;
  color: #253642;
}

.result-data-field-wrap {
  .single-result-data-wrap {
    display: flex;
    //   align-items: center;
    gap: 10px;
    width: 100%;
    .result-title {
      label {
        margin-bottom: 0;
        font-size: 14px;
        width: 150px;
        font-weight: 500;
      }
      p {
        margin-bottom: 0;
        font-size: 16px;
        width: 150px;
      }
    }
    .result-field {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}
