//======================================
.blog-details-wrap {
  margin-top: 30px;
  .dashboard--new-main-content-area-wrap.blog-details-page {
    background: #fff;
    //   margin-top: 65px;
    > img {
      width: 100%;
      height: 450px;
      object-fit: cover;
      border-radius: 10px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .blog-details-content-body-area-wrap {
      padding: 50px;
      h6 {
        margin-bottom: 10px;
        font-size: 12px;
        text-transform: uppercase;
        color: #979699;
        font-weight: normal;
        b {
          color: #6f7478;
        }
      }
      h3 {
        font-size: 30px !important;
        line-height: 42px !important;
        font-weight: 700;
      }
      p {
        line-height: 22px;
        margin-bottom: 5px;
        color: #74788d;
      }
      .post-author-profile-info-area {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 40px;
        .profile-info-left {
          img {
            width: 45px;
            height: 45px;
          }
        }
        .profile-info-right {
          h4 {
            font-size: 18px;
            margin-bottom: 8px;
            line-height: 1;
          }
          p {
            margin-bottom: 0;
            line-height: 1;
          }
        }
      }
    }
  }
  .content-new-body-main-content-items {
    margin-bottom: 44px;
  }
  .dashboard--new-main-content-area-wrap {
    a.slider-text-info-ongoing-project {
      text-decoration: none;
      color: #253642;
      span {
        color: #74788d;
      }
    }
    .empty-blog-area-wrap {
      width: 100%;
      min-height: 350px;
      background: #fff;
      box-shadow: 0px 0px 20px 0px #efefef;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      h6 {
        font-size: 20px !important;
        margin: 15px 0;
        color: #74788d;
      }
      a {
        display: inline-block;
        padding: 10px 30px;
        background: #0052cc;
        color: #fff;
        border-radius: 8px;
        text-decoration: none;
        margin-top: 10px;
      }
    }
    /* the slides */
    .slick-slide {
      margin: 0 10;
    }
    /* the parent */
    .slick-list {
      margin: 0 -10;
    }
    .slick-slide > div {
      margin: 0 10px;
    }
    .slick-list {
      margin: 0 -10px;
    }
    .ongoing-project-title {
      margin-bottom: 24px;
      h3 {
        font-size: 28px !important;
        font-weight: 700;
        color: #495057;
        line-height: 40px !important;
        margin-bottom: 0;
        @media only screen and (max-width: 767px) {
          font-size: 22px !important;
        }
      }
    }
  }
  .blog-details-single-card-item .blog-image img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  .blog-post-single-post-right-sidebar a {
    color: #49507d;
  }
  .blog-details-sidebar-header-title {
    margin-bottom: 18px;
    h3 {
      font-weight: 700;
    }
  }
}
