.btn-packageDetails {
  position: static;
  width: 28px;
  height: 16px;
  left: 12px;
  top: 8px;

  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  /* Primary Color */

  color: #0052cc;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

li.profile-arrow-icon {
  position: relative;
}
.arrow-icon-show-hide {
  position: absolute;
  top: 10px;
  right: 15px;
  i {
    font-size: 20px;
  }
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem #556ee600 !important;
}

iframe {
  display: none;
}
//=================================
.paginationContainer ul {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  li{
    list-style: none;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &.prevNext{
      background: none;
      button{
        background: none;
        border: none;
        padding: 0;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &.activePage{
      background: #0052cc;
      color: #fff;
    }
    &:hover{
      background: #0052cc;
      color: #fff;
      cursor: pointer;
      &.prevNext{
       
        button{
          background: #0052cc;
      color: #fff;
        }
      }
    }
  }
}

//====================
form.range-excel.download.av-valid {
  display: flex;
  align-items: center;
  gap: 10px;
  .select-form-width {
    width: 150px;
}
}